import React from 'react';

const IconJc = () => (
  <svg
    width="256"
    height="60"
    viewBox="0 0 256 240"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="120" cy="120" r="120" fill="#013A9D" />
    <path
      d="M88.29 96.15C88.29 95.3433 88.8767 94.5367 90.05 93.73C91.2233 92.9233 92.36 92.52 93.46 92.52C94.6333 92.52 95.5133 92.7767 96.1 93.29C96.76 93.8033 97.1267 94.4267 97.2 95.16C98.74 114.96 99.51 127.5 99.51 132.78C99.51 137.987 99.4367 141.91 99.29 144.55C99.1433 147.19 98.7767 150.05 98.19 153.13C97.6033 156.137 96.76 158.52 95.66 160.28C93.24 164.387 89.3533 166.44 84 166.44C78.6467 166.44 74.2833 164.68 70.91 161.16C67.5367 157.567 65.85 152.433 65.85 145.76C65.85 144.66 66.4 143.67 67.5 142.79C68.6733 141.837 69.92 141.36 71.24 141.36C72.8533 141.36 73.8433 142.863 74.21 145.87C75.1633 154.377 78.3533 158.63 83.78 158.63C85.1733 158.63 86.3467 158.19 87.3 157.31C88.3267 156.357 89.0967 155.257 89.61 154.01C90.1233 152.69 90.5267 150.967 90.82 148.84C91.26 145.76 91.48 142.093 91.48 137.84C91.48 133.513 90.93 125.667 89.83 114.3C88.8033 102.933 88.29 96.8833 88.29 96.15Z"
      fill="white"
    />
    <path
      d="M170.4 57.9C177.1 57.9 184.5 59.2 192.6 61.8C194.4 63.2 195.3 64.75 195.3 66.45C195.3 68.05 194.45 69.5 192.75 70.8C191.15 72 189.3 72.6 187.2 72.6C181.8 71.2 176.75 70.5 172.05 70.5C161.25 70.5 153.05 73.7 147.45 80.1C141.85 86.5 139.05 96 139.05 108.6C139.05 121.2 142.35 131.05 148.95 138.15C152.35 141.85 156.4 143.7 161.1 143.7C165.8 143.7 173.3 141.2 183.6 136.2C187.2 134.5 189.6 133.65 190.8 133.65C193.4 133.65 194.7 135 194.7 137.7C194.7 140.4 193.45 142.65 190.95 144.45C182.35 148.95 175.65 152 170.85 153.6C166.05 155.1 161.9 155.85 158.4 155.85C151.2 155.85 145.05 153.2 139.95 147.9C130.85 138.1 126.3 124.75 126.3 107.85C126.3 90.85 130.6 78.3 139.2 70.2C147.9 62 158.3 57.9 170.4 57.9Z"
      fill="white"
    />
  </svg>
);

export default IconJc;
